import { Alert } from '@mui/material'
import { formatDateTimeShort } from "../../../utils/datetime";

const StophelingAlert = ({ isStolen, lastStolen }: { isStolen: boolean; lastStolen: string }) => {
  if (isStolen) {
    return (
      <Alert variant="standard" severity="error" sx={{ mt: 2 }}>
        Stopheling status: STOLEN
      </Alert>
    )
  }

  if (lastStolen) {
    return (
      <Alert variant="standard" severity="error" sx={{ mt: 2 }}>
        Stopheling status: Not stolen
        <br />
        Previously Recorded as Stolen in SH on {formatDateTimeShort(lastStolen)}
      </Alert>
    )
  }

  return (
    <Alert variant="standard" severity="info" sx={{ mt: 2 }}>
      Stopheling status: Not stolen or reported
    </Alert>
  )
}

export default StophelingAlert
