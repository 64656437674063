import { Link, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import Get from '../../../components/Get'
import { VehicleType } from '../types'
import VehicleInfo from '../components/VehicleInfo'
import {
  isAdministrationField,
  isDetailsField,
  isSeizureField,
  isTelematicsField,
  isAssetField,
  isKiwaCertificateFields,
  detailFields,
  isVbvTrackingFields,
  isRentalReportFields,
  isRecoveryReportFields,
} from '../fields'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../users/authSlice'
import useRouteGuard from '../../../hooks/useRouteGuard'
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material'
import { camelToString, fields } from '../../vin-search/VinSearchItem'
import RDWTables from '../../rdw/components/RDWTables'
import UpdateLog from '../components/UpdateLog'
import GroupFields from '../../../components/GroupFields'
import InternalNotes from '../components/InternalNotes'
import { useEffect, useState } from 'react'
import api from '../../../utils/api'
import { setIsPageLoading } from '../../../appSlice'
import env from '../../../constants/env'
import StophelingSection from '../components/StophelingSection'

const S = {
  Page: styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  Buttons: styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    @media print {
      display: none;
    }
    @media screen and (max-width: 900px) {
      margin-top: 16px;
      justify-content: flex-start;
    }
  `,
  ButtonLink: styled(Link)`
    font-size: 12px;
  `,
  ColWrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
  `,
  Col: styled.div`
    padding: 24px;
    width: 50%;
    @media screen and (max-width: 900px) {
      width: 100%;
    }
  `,
  Wrapper: styled.div`
    position: relative;
    background: white;
    z-index: 9;
    margin: 8px;
    padding: 8px;
    border-radius: 4px;
  `,
  Td: styled.td`
    width: 50%;
    text-transform: capitalize;
  `,
}

export const sortByKey = (a, b) => {
  if (a.key < b.key) {
    return -1
  }
  if (a.key > b.key) {
    return 1
  }
  return 0
}

const RDW = (props: { plate: string }) => {
  const plate = (props.plate || '-').toUpperCase()
  return (
    <Get url={`/rdw/${plate}/`} defaultErrorMessage="Plate not found">
      {(props) => <RDWTables {...props} />}
    </Get>
  )
}

const VehicleView = (props: RouteComponentProps<{ id: string }>) => {
  useRouteGuard(['canReadVehicles', 'canReportRental'], ['canViewDatabase', 'canViewRentalReport'])
  const user = useSelector(selectUser)
  const canEdit = user.isSuperuser || user.canEditVehicles || user.canReportRental
  const id = props.match.params.id
  const [data, setData] = useState<VehicleType | undefined>(undefined)
  const [histories, setHistories] = useState<VehicleType['history']>([])
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      try {
        // disabled as its buggy
        dispatch(setIsPageLoading(false))
        const vehicleResp = await api.get(`/vehicles/${id}/`)
        const data = vehicleResp.data
        const rentalReportData = data.rentalReport || {
          theftReport: [],
          insuranceDocs: [],
          vehicleRegistrationPapers: [],
          authorizationToTowDocs: [],
        }
        setData({
          ...vehicleResp.data,
          theftReport: [...data.theftReport, ...rentalReportData.theftReport],
          insuranceDocs: [...data.insuranceDocs, ...rentalReportData.insuranceDocs],
          vehicleRegistrationPapers: [
            ...data.vehicleRegistrationPapers,
            ...rentalReportData.vehicleRegistrationPapers,
          ],
          authorizationToTowDocs: [
            ...data.authorizationToTowDocs,
            ...rentalReportData.authorizationToTowDocs,
          ],
        })
        const historiesResp = await api.get(`/vehicles/${id}/histories/`)
        setHistories(historiesResp.data)
      } catch (err) {
        console.log(err)
      } finally {
        dispatch(setIsPageLoading(false))
      }
    }
    fetchData()
  }, [id])

  return (
    <S.Page>
      {data && (
        <>
          <Box sx={{ padding: '24px' }}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between" mb={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h4">SMVIU Data</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <S.Buttons>
                  {canEdit && (
                    <S.ButtonLink to={`/vehicles/${id}/edit/`}>
                      <Button variant="outlined" size="small">
                        Edit
                      </Button>
                    </S.ButtonLink>
                  )}

                  <S.ButtonLink to={`/vehicles/${id}/telematics-report/`}>
                    <Button variant="outlined" size="small">
                      Telematics Report
                    </Button>
                  </S.ButtonLink>

                  <S.ButtonLink to={`/vehicles/${id}/seizure-report/`}>
                    <Button variant="outlined" size="small">
                      Seizure Report
                    </Button>
                  </S.ButtonLink>

                  <S.ButtonLink to={`/vehicles/${id}/vehicle-report/`}>
                    <Button variant="outlined" size="small">
                      Vehicle Report
                    </Button>
                  </S.ButtonLink>
                </S.Buttons>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="stretch">
              <Grid item xs={12} lg={6}>
                <Paper sx={{ p: 2, height: '100%' }}>
                  <VehicleInfo
                    header="Vehicle Details"
                    fieldFilter={isDetailsField}
                    vehicle={data}
                    compact
                    fieldSorting={(a, b) => {
                      return (
                        // @ts-ignore
                        detailFields.indexOf(a.key) -
                        // @ts-ignore
                        detailFields.indexOf(b.key)
                      )
                    }}
                  />

                  <VehicleInfo header="Files" fieldFilter={isAssetField} vehicle={data} compact />

                  <VehicleInfo
                    header="Telematics / GPS Data"
                    fieldFilter={isTelematicsField}
                    vehicle={{
                      ...data,
                      ...data.kiwaCertificate,
                      scmClass: !isEmpty(data.kiwaCertificate),
                    }}
                    compact
                    actionsRender={() => (
                      <div>
                        {data.hasLocation ? (
                          <Button variant="outlined">
                            <Link to={`/map?vehicleId=${data.id}&mode=detail`}>Map</Link>
                          </Button>
                        ) : null}
                        {data.tracker &&
                        (user.canReadVehicles || user.isSuperuser || user.canReportRental) ? (
                          <Button variant="outlined" sx={{ ml: 2 }}>
                            <Link to={`/trackers?imei=${data.tracker}`}>Tracker</Link>
                          </Button>
                        ) : null}
                      </div>
                    )}
                  />

                  {!isEmpty(data.kiwaCertificate) && (
                    <VehicleInfo
                      header="SCM Class"
                      fieldFilter={isKiwaCertificateFields}
                      vehicle={{ ...data, ...data.kiwaCertificate }}
                      compact
                    />
                  )}

                  {!isEmpty(data.vbvTrackingLog) && (
                    <VehicleInfo
                      header="Vbv Sent Updates"
                      fieldFilter={isVbvTrackingFields}
                      vehicle={{ ...data, ...data.vbvTrackingLog }}
                      compact
                    />
                  )}

                  {!isEmpty(data.rentalReport) && (
                    <VehicleInfo
                      header="Rental Report"
                      fieldFilter={isRentalReportFields}
                      vehicle={{ ...data.rentalReport }}
                      compact
                    />
                  )}
                </Paper>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Paper sx={{ p: 2, height: '100%' }}>
                  <InternalNotes vehicleId={id} />

                  <VehicleInfo
                    header="Seizure Details"
                    fieldFilter={isSeizureField}
                    vehicle={data}
                    compact
                  />

                  <VehicleInfo
                    header="Recovery report"
                    fieldFilter={isRecoveryReportFields}
                    vehicle={data}
                    compact
                  />

                  <VehicleInfo
                    fieldSorting={sortByKey}
                    header="Admin Details"
                    fieldFilter={isAdministrationField}
                    vehicle={data}
                    compact
                  />

                  <UpdateLog logs={histories} />
                </Paper>
              </Grid>
            </Grid>

            {data.webData && (
              <Paper sx={{ p: 2, my: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <GroupFields
                      data={data.webData}
                      settings={[
                        {
                          group: 'Web hits',
                          fields: fields.map((field) => ({
                            field,
                            label: camelToString(field),
                          })),
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Paper>
            )}

            <Divider style={{ marginBottom: 20, marginTop: 36 }} />
          </Box>
          {!env.isSixt && (
            <Box style={{ padding: 24 }}>
              <Typography variant="h4" gutterBottom>
                RDW Data
              </Typography>
              <RDW plate={data.plate} />
            </Box>
          )}
          {data?.helingRaw && (
            <StophelingSection
              vehicleId={id}
              data={{
                helingRaw: data.helingRaw,
                helingStatus: data.helingStatus,
                helingLastChecked: data.helingLastChecked,
              }}
            />
          )}
        </>
      )}
    </S.Page>
  )
}

export default VehicleView
