import { Box, Button } from '@mui/material'
import React from 'react'
import { HelingInfo } from '../../rdw/views/PlateSearch'
import { HelingResponse } from '../types'
import { axios } from '../../../utils/api'
import { useDispatch } from 'react-redux'
import { setIsPageLoading } from '../../../appSlice'
import { showErrorNotification } from '../../notifications/notificationsSlice'

const StophelingSection = ({ vehicleId, data }: { vehicleId: string; data: HelingResponse }) => {
  const dispatch = useDispatch()

  const refetch = async () => {
    dispatch(setIsPageLoading(true))
    try {
      const data = await axios.post(`/vehicles/${vehicleId}/sync-stopheling/`)
      window.location.reload()
    } catch (e) {
      dispatch(showErrorNotification('Failed to refetch Stopheling data'))
    } finally {
      dispatch(setIsPageLoading(false))
    }
  }

  return (
    <Box px={2} mt={4}>
      <Button variant="contained" size="small" onClick={refetch}>
        Refetch Stopheling data
      </Button>
      <HelingInfo
        data={{
          helingStatus: data.helingStatus,
          helingRaw: data.helingRaw,
          helingLastChecked: data.helingLastChecked,
        }}
      />
    </Box>
  )
}

export default StophelingSection
