import { useState } from 'react'
import { Box, Collapse, Dialog, IconButton, TableCell, TableRow } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { OrganizationType, UserType } from '../types'
import { formatDateTime, toDate } from '../../../utils/datetime'
import { UserForm } from '../ManageUserPermissions'
import ManagePageRoles from './ManagePageRoles'

const UserPermissionRow = (props: {
    user: UserType
    onSubmit: () => void
    organizations?: OrganizationType[]
}) => {
    const { user } = props
    const [isEditing, setIsEditing] = useState(false)
    const [expandingRow, setExpandingRow] = useState(false)
    const hide = () => setIsEditing(false)
    const onSubmit = () => {
        hide()
        props.onSubmit()
    }

    const showEditModal = (evt) => {
        evt.stopPropagation()
        setIsEditing(true)
    }

    return (
        <>
            <Dialog open={isEditing} onClose={hide} maxWidth="lg" fullWidth>
                <Box p="20px">
                    <UserForm user={user} onSubmit={onSubmit} organizations={props.organizations} />
                </Box>
            </Dialog>

            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => setExpandingRow(!expandingRow)}
            >
                <TableCell>
                    <IconButton sx={{ mr: 1 }} size="small" onClick={() => setExpandingRow(!expandingRow)}>
                        {expandingRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <a href="#" onClick={showEditModal}>
                        {user.email}
                    </a>
                </TableCell>
                <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                <TableCell>{formatDateTime(user.lastLogin && toDate(user.lastLogin))}</TableCell>
                <TableCell>{user.canEditVehicles && 'x'}</TableCell>
                <TableCell>{user.canReadVehicles && 'x'}</TableCell>
                <TableCell>{user.canReportMissing && 'x'}</TableCell>
                <TableCell>{user.canReportSeizure && 'x'}</TableCell>
                <TableCell>{user.canReportWok && 'x'}</TableCell>
                <TableCell>{user.canReportRental && 'x'}</TableCell>
                <TableCell>{user.canSearchVin && 'x'}</TableCell>
                <TableCell>{user.canRegisterContainer && 'x'}</TableCell>
                <TableCell>{user.canStophelingSearch && 'x'}</TableCell>
                </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={expandingRow} timeout="auto" unmountOnExit>
                        <ManagePageRoles user={user} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default UserPermissionRow
