import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../features/users/authSlice'
import { useHistory } from 'react-router-dom'
import { access } from 'fs'

type PermissionType =
    | 'canReadVehicles'
    | 'canEditVehicles'
    | 'canReportMissing'
    | 'canReportSeizure'
    | 'canReportWok'
    | 'canReportRental'
    | 'isSuperuser'
    | 'canSearchVin'
    | 'canRegisterContainer'
    | 'canStophelingSearch'

type ViewRoleType =
    | 'canViewDashboard'
    | 'canViewPlateSearch'
    | 'canViewRdw'
    | 'canViewDatabase'
    | 'canViewThefts'
    | 'canViewNewSeizure'
    | 'canViewReportForSearch'
    | 'canViewSecurity'
    | 'canViewRepatriation'
    | 'canViewWokRequest'
    | 'canViewRentalReport'
    | 'canViewTrackers'
    | 'canViewWebhits'
    | 'canViewContainers'

const useRouteGuard = (
    requiredPermission?: PermissionType | PermissionType[],
    viewRole?: ViewRoleType | ViewRoleType[]
) => {
    const user = useSelector(selectUser)
    const history = useHistory()
    const hasRequiredPermission =
        !requiredPermission ||
        (Array.isArray(requiredPermission)
            ? // Use OR logic for requiredPermission array
              // For eg: requiredPermission = ['canReadVehicles', 'canReportRental'],
              // mean if user can access if they have canReadVehicles or canReportRental permission
              requiredPermission.reduce((acc, cur) => user[cur] || acc, false)
            : user[requiredPermission])
    const hasViewRole =
        !viewRole ||
        (Array.isArray(viewRole) ? viewRole.reduce((acc, cur) => user[cur] || acc, false) : user[viewRole])
    const hasPermission =
        // Don't redirect before fetching user
        !user ||
        // Check permission
        (hasRequiredPermission && hasViewRole) ||
        // Superuser always has access
        user.isSuperuser

    useEffect(() => {
        if (!hasPermission) {
            history.replace('/')
        }
    }, [hasPermission, requiredPermission, history])

    useEffect(() => {
      if (user && !user.enabled2fa) {
        history.replace('/security/')
        alert('Please enable 2FA')
      }
    }, [user])
}

export default useRouteGuard
