import React, { useState } from 'react'
import Get from '../../../components/Get'
import { Box, Button, Grid, Pagination, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectUser } from '../../users/authSlice'
import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import { formatTimeAgo } from '../../../utils/datetime'

const LIMIT = 10

const SearchHistory = ({ version, handleSearch }: { version: number, handleSearch: (v: string) => void }) => {
  const user = useSelector(selectUser)
  const [page, setPage] = useState(1)
  const [userFilter, setUserFilter] = useState(user.id)
  const [allSearches, setAllSearches] = useState(false)
  const filter = {
    user: userFilter && `${userFilter}`,
    limit: LIMIT.toString(),
    offset: (LIMIT * (page - 1)).toString(),
  }
  const searchParams = new URLSearchParams(omitBy(filter, isEmpty))

  const changeSearchesType = () => {
    if (allSearches) {
      setUserFilter(user.id)
    } else {
      setUserFilter(undefined)
    }
    setAllSearches(!allSearches)
  }

  return (
    <>
      <Box display="flex" gap="40px" alignItems="center" my={4}>
        <Typography variant="h5">{allSearches ? 'All searches' : 'My search history'}</Typography>
        {user.isSuperuser && (
          <Button onClick={changeSearchesType} variant="contained" size="small">
            {allSearches ? 'Show my searches' : 'Show all searches'}
          </Button>
        )}
      </Box>
      <Get
        key={`${version}__${searchParams.toString()}`}
        url={`/stopheling-search/?${searchParams}`}
        defaultErrorMessage="Not found"
      >
        {({ data }) => (
          <>
            <p>Total of {data.count} searches</p>
            {data?.results?.map((item) => (
              <Box
                sx={{
                  padding: '4px',
                  borderRadius: '3px',
                  background: 'rgba(0,0,0,0.07)',
                  margin: '4px 0',
                }}
              >
                <Grid container alignItems="center" height={{ md: '24px', xs: 'auto' }}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={2}
                    onClick={() => handleSearch(item.search)}
                    sx={{ cursor: 'pointer', color: 'darkblue' }}
                  >
                    {item.search}
                  </Grid>
                </Grid>
                {allSearches && (
                  <Typography variant="body1" color="blueviolet" mt={0.5} fontSize={14}>
                    User: {item.user.email}
                  </Typography>
                )}
                <Typography variant="caption" color="gray">
                  {formatTimeAgo(item.createdAt)}
                </Typography>
              </Box>
            ))}
            <Pagination
              count={Math.ceil(data.count / LIMIT)}
              shape="rounded"
              onChange={(_, _page) => setPage(_page)}
              page={page}
              color="primary"
              siblingCount={0}
              sx={{ mt: 2, mb: 4 }}
            />
          </>
        )}
      </Get>
    </>
  )
}

export default SearchHistory
