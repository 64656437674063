import { useState, useEffect } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material'
import api from '../../../utils/api'
import { formatDateTime } from '../../../utils/datetime'

interface SearchLog {
  id: number
  createdAt: string
  searchText: string
  rawResponse: any[]
  responseStatus: number
  isStolen: boolean
}

interface SearchLogsResponse {
  count: number
  next: string | null
  previous: string | null
  results: SearchLog[]
}

/**
 * Example response
 * {"count":1,"next":null,"previous":null,"results":[{"id":1,"createdAt":"2025-01-30T16:35:43.801986Z","searchText":"S082LJ","rawResponse":[{"id":2443561,"type":"TUCSON","brand":"HYUNDAI","color":"WIT","matchType":"Hit","vinNumber":"TMAJB812DPJ155927","resultType":"Stolen","uniqueNumber":"","policeForceCode":"PL2300","policeForceName":"EENHEID LIMBURG","motorSerialNumber":"","registrationNumber":"2025013949","productCategoryName":"PERSONENAUTO","licensePlateRegistrationCode":"S082LJ"}],"responseStatus":200,"isStolen":true}]}
 */

const SearchLogsView = () => {
  const [logs, setLogs] = useState<SearchLog[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchQuery, setSearchQuery] = useState('')
  const [isStolen, setIsStolen] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const fetchLogs = async () => {
    try {
      setLoading(true)
      let url = `/stopheling-search-logs/?limit=${rowsPerPage}&offset=${page * rowsPerPage}`
      
      if (searchQuery) {
        url += `&search=${encodeURIComponent(searchQuery)}`
      }
      
      if (isStolen) {
        url += `&is_stolen=${isStolen}`
      }

      const response = await api.get<SearchLogsResponse>(url)
      setLogs(response.data.results)
      setTotalCount(response.data.count)
    } catch (error) {
      console.error('Error fetching search logs:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchLogs()
  }, [page, rowsPerPage, searchQuery, isStolen])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
    setPage(0)
  }

  const handleIstolenChange = (event: any) => {
    setIsStolen(event.target.value)
    setPage(0)
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Stopheling Search Logs
      </Typography>

      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Search"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by text..."
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Filter by Status</InputLabel>
            <Select
              value={isStolen}
              label="Filter by Status"
              onChange={handleIstolenChange}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="true">Stolen</MenuItem>
              <MenuItem value="false">Not Stolen</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Search Text</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>License Plate</TableCell>
              <TableCell>VIN</TableCell>
              <TableCell>Match Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log) => (
              <TableRow key={log.id}>
                <TableCell>{log.id}</TableCell>
                <TableCell>{log.searchText}</TableCell>
                <TableCell>{formatDateTime(log.createdAt)}</TableCell>
                <TableCell>
                  {log.isStolen ? (
                    <Typography color="error">Stolen</Typography>
                  ) : (
                    <Typography color="success">Not Stolen</Typography>
                  )}
                </TableCell>
                <TableCell>{log.rawResponse?.[0]?.brand || '-'}</TableCell>
                <TableCell>{log.rawResponse?.[0]?.type || '-'}</TableCell>
                <TableCell>{log.rawResponse?.[0]?.color || '-'}</TableCell>
                <TableCell>{log.rawResponse?.[0]?.licensePlateRegistrationCode || '-'}</TableCell>
                <TableCell>{log.rawResponse?.[0]?.vinNumber || '-'}</TableCell>
                <TableCell>{log.rawResponse?.[0]?.matchType || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  )
}

export default SearchLogsView 